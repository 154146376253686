import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../authentication/AuthContext';
import { useDispatch } from 'react-redux';
import { clearMessages, clearResponses } from '../../Redux/actions/chatActions';
import './Navbar.css';
import logo from '../../assets/Logo.png';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, logout } = useAuth();

  const isHomePage = location.pathname === '/';
  const isAuthPage = location.pathname === '/auth';
  const isChatPage = location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

 const handleClearChat = () => {
    dispatch(clearMessages());
    dispatch(clearResponses());
  };

  return (
<nav className={`navbar ${scrolled ? 'scrolled' : ''} ${isHomePage ? 'navbar-home' : 'navbar-chat'} ${isAuthPage ? 'navbar-auth' : ''}`}>
  <div className={`navbar-container ${isChatPage ? 'navbar-container-chat' : ''}`}>
    <div className="navbar-logo">
      {isAuthenticated && (
        <Link to="/">
          <img src={logo} alt="SherpaRx Logo" className="logo-image" />
        </Link>
      )}
    </div>
    <div className="navbar-buttons">
      <div className="navbar-center">
        {isChatPage && isAuthenticated && (
          <button onClick={handleClearChat} className="navbar-clear-chat">
            Clear Chat
          </button>
        )}
      </div>
      <div className="navbar-right">
        {isAuthenticated ? (
          <button onClick={handleLogout} className="navbar-cta">Logout</button>
        ) : (
          <Link to="/auth" className="navbar-cta">Login / Register</Link>
        )}
      </div>
    </div>
  </div>
</nav>
  );
};

export default Navbar;