import React, { useState, useEffect } from 'react';
import ChatArea from './ChatArea';
import './ChatPage.css';
import UserInput from './UserInput';
import FileUploader from './FileUploader';

const ChatPage  = () => {
  // 1. Manage modal state
  const [isModalOpen, setModalOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [responses, setResponses] = useState([]);
   // State to manage the selected chat type
  const [selectedOption, setSelectedOption] = useState('Helix');

  return (
  <div>
    <div className="helix-chatPage" id="home">
       <div className="chatPage-topMessage">
        </div>

      {/* 2. Modal UI */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
           <FileUploader />
            <button className="close-modal-btn" onClick={() => setModalOpen(false)}>X</button>
          </div>
        </div>
      )}
      <div className= "chatArea-inside-container">
      <ChatArea/>
    </div>
      {/* Adding the UserInput component here */}
      {/* 3. Pass down the setModalOpen function */}
          <UserInput
            setMessages={setMessages}
            setResponses={setResponses}
            openModal={() => setModalOpen(true)}
            selectedOption={selectedOption}
          />
      {/* ... other content you might want to add later */}
    </div>
    </div>
  );
}

export default ChatPage;