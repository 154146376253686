import React, { useState, useRef, useEffect } from "react";
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import axios from "axios";
import './FileUploader.css';
import { useDispatch } from 'react-redux';
import { setBlobUrl } from '../../Redux/actions/PDFActions';
import { addMessage, addResponse } from '../../Redux/actions/chatActions';

function FileUploader() {
  const [activeTab, setActiveTab] = useState('document');
  const [isUploaded, setIsUploaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);
  const [upgradeDisplay, setUpgradeDisplay] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const dispatch = useDispatch();
  const [snpReport, setSnpReport] = useState("");

  useEffect(() => {
    setFileName("");
    setSelectedFile(null);
    setErrorMessage("");
  }, [activeTab]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    processFile(file);
  };

  const processFile = (file) => {
    if (file && isValidFileType(file)) {
      setSelectedFile(file);
      setFileName(file.name);
      setErrorMessage("");
    } else if (!file) {
      setFileName("");
      setSelectedFile(null);
    } else {
      setErrorMessage(`Invalid file type. Please upload a ${getAcceptedFileTypes()} file.`);
      setFileName("");
      setSelectedFile(null);
    }
  };

  const isValidFileType = (file) => {
    if (activeTab === 'document') {
      // Document tab is blocked, so no file type is valid
      return false;
    } else if (activeTab === 'snp') {
      const validTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "text/csv",
        "application/csv"
      ];
      const extension = file.name.split('.').pop().toLowerCase();
      return validTypes.includes(file.type) || ['xlsx', 'xls', 'csv'].includes(extension);
    }
    return false;
  };

  const getAcceptedFileTypes = () => {
    return activeTab === 'document' ? "PDF" : "Excel or CSV";
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragActive(false);
    const file = event.dataTransfer.files[0];
    processFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragActive(false);
  };

  const handleUpload = async () => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);

    const uploadEndpoint = activeTab === 'snp'
      ? `${process.env.REACT_APP_API_URL}/upload-snp`
      : `${process.env.REACT_APP_API_URL}/upload`;

    try {
      const response = await axios.post(uploadEndpoint, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.status === 200) {
        setIsUploaded(true);
        setTimeout(() => {
          setIsUploaded(false);
          setFileName("");
          setSelectedFile(null);
        }, 3000);

        if (activeTab === 'snp') {
          const report = response.data.comprehensive_report;
          dispatch(addMessage('SNP Panel Report:'));
          dispatch(addResponse({
            answer: report,
            source: 'SNP Panel Analysis'
          }));
          console.log('Query results:', response.data.query_results);
        } else {
          dispatch(setBlobUrl(response.data.blob_url));
        }
      } else {
        setErrorMessage(response.data?.error || 'Failed to upload file.');
      }
    } catch (error) {
      handleUploadError(error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleUploadError = (error) => {
    if (error.response?.status === 400 && error.response.data.status === 'Page limit exceeded.') {
      setErrorMessage("Upload limit reached. Please upgrade your account.");
      setUpgradeDisplay(true);
    } else if (error.response?.status === 413) {
      setErrorMessage("File size too large!");
    } else {
      setErrorMessage(error.response?.data.message || "An error occurred while uploading the file.");
    }
  };

  return (
    <div className="creative-file-uploader">
      <div className="tab-slider">
        <div
          className="slider-background"
          style={{ transform: `translateX(${activeTab === 'document' ? '0%' : '100%'})` }}
        />
        <button
          className={`tab-button ${activeTab === 'document' ? 'active' : ''}`}
          onClick={() => setActiveTab('document')}
        >
          Document
        </button>
        <button
          className={`tab-button ${activeTab === 'snp' ? 'active' : ''}`}
          onClick={() => setActiveTab('snp')}
        >
          SNP Panel
        </button>
      </div>

      <div
        className={`drop-zone ${dragActive ? 'drag-active' : ''} ${activeTab === 'document' ? 'disabled' : ''}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => activeTab !== 'document' && fileInputRef.current.click()}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          accept={activeTab === 'document' ? '.pdf' : '.xlsx,.xls,.csv'}
          disabled={activeTab === 'document'}
        />
        <div className="drop-zone-content">
          {activeTab === 'document' ? (
            <p>PDF upload is only available to Sherpa RX Clients</p>
          ) : selectedFile ? (
            <>
              <div className="file-icon">📊</div>
              <p className="file-name">{fileName}</p>
            </>
          ) : (
            <>
              <div className="upload-icon">📁</div>
              <p>Drag & Drop your {getAcceptedFileTypes()} file here or click to browse</p>
            </>
          )}
        </div>
      </div>

      {errorMessage && (
        <div className="error-message">
          {errorMessage}
        </div>
      )}

      <button
        className={`upload-button ${isUploading ? 'uploading' : ''} ${isUploaded ? 'uploaded' : ''} ${activeTab === 'document' ? 'disabled' : ''}`}
        onClick={handleUpload}
        disabled={isUploading || !selectedFile || activeTab === 'document'}
      >
        {activeTab === 'document' ? 'Only available to Sherpa RX Clients' : isUploading ? 'Uploading...' : isUploaded ? 'Uploaded!' : 'Upload'}
      </button>

      {upgradeDisplay && (
        <div className="upgrade-message">
          <p>Need more storage? <Link to="/pricing">Upgrade your account!</Link></p>
        </div>
      )}
    </div>
  );
}

export default FileUploader;