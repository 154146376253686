import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './AuthPage.css';

const AuthPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const { login, register } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      const result = isLogin
        ? await login(email, password)
        : await register(email, password);

      if (result.success) {
        navigate('/');
      } else {
        setMessage(result.message);
      }
    } catch (error) {
      setMessage('An unexpected error occurred. Please try again.');
      console.error('Auth error:', error);
    }
  };

  return (
    <div className="auth-container">
      <div className={`auth-form-container ${isLogin ? 'login' : 'register'}`}>
        <div className="auth-info">
          <h2>{isLogin ? 'Welcome Back' : 'Join Us'}</h2>
          <p>{isLogin ? 'Log in to your account to continue' : 'Create an account to get started'}</p>
        </div>
        <form onSubmit={handleSubmit} className="auth-form">
          <div className="form-group">
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
          </div>
          <div className="form-group">
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
          </div>
          <button type="submit" className="submit-btn">
            {isLogin ? 'Log In' : 'Sign Up'}
          </button>
        </form>
        {message && <p className="message">{message}</p>}
        <button onClick={() => setIsLogin(!isLogin)} className="toggle-btn">
          {isLogin ? 'Need an account? Register' : 'Already have an account? Log in'}
        </button>
      </div>
      <div className="auth-image"></div>
    </div>
  );
};

export default AuthPage;