import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie'; // Import js-cookie

// Create a context for authentication
const AuthContext = createContext();

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  // State to track if the user is authenticated
  const [csrfToken, setCsrfToken] = useState(null);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // State to store user information
  const [user, setUser] = useState(null);

  // Effect to check authentication status when the component mounts
  useEffect(() => {
    checkAuthStatus();
  }, []);

  // Function to check the user's authentication status
  const checkAuthStatus = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/verify-auth`, {
        withCredentials: true // Ensures cookies are sent with the request
      });
      setIsAuthenticated(true);
      setUser(response.data.user);
    } catch (error) {
      console.error('Auth status check failed:', error);
      setIsAuthenticated(false);
      setUser(null);
    }
  };

  // Function to handle user login
 const login = async (email, password) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`,
      { email, password },
      { withCredentials: true }
    );

    // Read CSRF token from the cookie
    const csrfToken = Cookies.get('csrf_access_token');
    setCsrfToken(csrfToken);

    setIsAuthenticated(true);
    setUser(response.data.user);
    return { success: true, message: 'Login successful' };
  } catch (error) {
    console.error('Login failed:', error);
    return { success: false, message: error.response?.data?.message || 'Login failed' };
  }
};

  // Function to handle user logout
  const logout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, { withCredentials: true });
      return { success: true, message: 'Logout successful' };
    } catch (error) {
      console.error('Logout failed:', error);
      return { success: false, message: 'Logout failed' };
    } finally {
      // Always clear the local auth state, even if the server request fails
      setIsAuthenticated(false);
      setUser(null);
    }
  };

  // Function to handle user registration
  const register = async (email, password) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`,
        { email, password },
        { withCredentials: true }
      );
      setIsAuthenticated(true);
      setUser(response.data.user);
      return { success: true, message: 'Registration successful' };
    } catch (error) {
      console.error('Registration failed:', error);
      return { success: false, message: error.response?.data?.message || 'Registration failed' };
    }
  };

  // Provide the authentication context to child components
  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, csrfToken, logout, register, checkAuthStatus }}>
      {children}
    </AuthContext.Provider>
  );
};