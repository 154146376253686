import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserInput.css';
import uploadIcon from '../../assets/PDF-upload.png';
import Submit from '../../assets/Submit.png';
import { addMessage, addResponse } from '../../Redux/actions/chatActions';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../Components/authentication/AuthContext'; // Import useAuth hook
import Cookies from 'js-cookie'; // Import js-cookie

const adjustHeight = (element) => {
  element.style.height = '30px';
  element.style.height = `${Math.min(element.scrollHeight, 300)}px`;
};

// CHANGED: Removed openModal and selectedOption props as they're no longer needed
function SherpaUserInput({ sessionId, openModal }) {
  const { csrfToken } = useAuth();
  const [message, setMessage] = useState('');
  // REMOVED: const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const { user } = useAuth(); // Get user from AuthContext


  const messages = useSelector(state => state.chat.messages);
  const responses = useSelector(state => state.chat.responses);

  useEffect(() => {
    console.log('Current messages in Redux:', messages);
  }, [messages]);

  useEffect(() => {
    console.log('Current responses in Redux:', responses);
  }, [responses]);

  useEffect(() => {
    const textarea = document.querySelector('.message-input');
    if (textarea) {
      adjustHeight(textarea);
    }
  }, [message]);

  // REMOVED: useEffect for logging environment variables

const handleSubmit = async (event) => {
    event.preventDefault();
    const currentMessage = message;
    setMessage('');

    dispatch(addMessage(currentMessage));

    try {
    //read the csrftoken
      const csrfToken = Cookies.get('csrf_access_token');

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/sherpa_chat`,
        {
          message: currentMessage,
          session_id: sessionId,
        },
        {
          withCredentials: true,
          headers: {
            'X-CSRF-TOKEN': csrfToken
          }
        }
      );
      dispatch(addResponse(response.data));
    } catch (error) {
      console.error('Error:', error);
      let errorMessage = "An error occurred, please try again later.";
      if (error.response) {
        console.log('Error response:', error.response);
        errorMessage = error.response.status === 500
          ? "An error occurred, try resubmitting your question again"
          : error.response.status === 401
          ? "Please refresh your page and log in again."
          : "An error occurred with your request.";
      } else if (error.request) {
        console.log('Error request:', error.request);
        errorMessage = "Network Error. Please check your connection and try again.";
      }
      console.log('Dispatching error response');
      dispatch(addResponse({ answer: errorMessage, source: "No sources available" }));
    }
  };

  // REMOVED: handleFileChange function
  // REMOVED: handleFileUpload function

  return (
  <div className= "userInput-main">
    <div className="userInput-root">
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <div className="pdf-button-container">
            <button className="pdf-button" type="button" onClick={openModal}>
              <img src={uploadIcon} alt="uploadIcon" />
            </button>
          </div>
          <textarea
            className="message-input"
            type="text"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              adjustHeight(e.target);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey && message.trim()) {
                e.preventDefault();
                handleSubmit(e);
              }
            }}
            placeholder="Enter your message"
          />
          <button className="input-button" type="submit" disabled={!message.trim()}>
            <img src={Submit} alt="SubmitIcon" />
          </button>
        </div>
      </form>
      {/* REMOVED: SNP panel upload UI */}
    </div>
    </div>
  );
}

export default SherpaUserInput;