import { createStore, combineReducers, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk'; // Import Redux Thunk
import chatReducer from './reducers/chatReducer'; // Import your chatReducer
import PDFReducer from './reducers/PDFReducer';
// Combine reducers, now including the chatReducer
const rootReducer = combineReducers({
  chat: chatReducer, // Add the chatReducer here
  pdfBlob: PDFReducer,

});

// Create the store with the rootReducer and apply the thunk middleware
const store = createStore(
  rootReducer,
  applyMiddleware(thunk) // Apply Redux Thunk middleware
);

export default store;
